/* eslint-disable max-lines-per-function */

import { makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface IDownloadAppButtons {
  className?: string;
}

export default function DownloadAppButtons({ className }: IDownloadAppButtons) {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={cx(styles.container, className)} style={{ boxSizing: 'content-box' }}>
      <a target="_blank" href="https://linktr.ee/downloadzaapi">
        <img
          style={{ backgroundColor: 'white', borderRadius: '27.7083px', padding: '20px 20.94px 20.94px 20px' }}
          src="https://d3a2p8n0vkdvcj.cloudfront.net/prod/app-assets/web-directory/qr-download-app.svg"
          className={styles.downloadAppQr}
        />
      </a>
      <div className={cx(styles.rightContainer)}>
        <Typography className={styles.scanToDownloadText}>{t('Scan to download')}</Typography>
        <a target="_blank" href="https://testflight.apple.com/join/piptFzzz">
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/storage.zaapi.co/prod/app-assets/web-directory/app-store.png"
            className={cx(styles.downloadAppButton, styles.appStoreButton)}
          />
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.zaapisellerapp">
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/storage.zaapi.co/prod/app-assets/web-directory/google-play.png"
            className={cx(styles.downloadAppButton, styles.googlePlayButton)}
          />
        </a>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      paddingTop: 46,
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadAppButton: {
    width: 155,
    height: 47,
    [theme.breakpoints.up('md')]: {
      width: 167,
      height: 50,
    },
  },
  appStoreButton: {
    marginTop: 20,
  },
  googlePlayButton: {
    marginTop: 13,
    [theme.breakpoints.up('md')]: {
      marginTop: 12,
    },
  },
  scanToDownloadText: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      fontFamily: 'Mulish',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 1,
      textAlign: 'center',
      textTransform: 'uppercase',
      color: 'white',
      marginBottom: 7,
    },
  },
  downloadAppQr: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: 163,
      height: 163,
      marginRight: 29,
    },
  },
}));
