import { useSelector } from 'react-redux';
import { sGetLanguageCode } from 'redux/store/StoreSelectors';

const useIsThai = (): boolean => {
  const storeLanguageCode = useSelector(sGetLanguageCode);

  return storeLanguageCode === 'th';
};

export default useIsThai;
