/* eslint-disable max-lines-per-function */

import { makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IStoreCategory } from 'redux/common/Common';
import Palette from 'styles/Palette';
import { Navigation, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICity } from './TopCityList/TopCityList.d';

interface ICustomSliderProps {
  className?: string;
  headerTxt?: string;
  ItemsComponent: React.FC<{ value: IStoreCategory | ICity | string }>;
  resources: IStoreCategory[] | ICity[];
}

enum ESwiperButton {
  NEXT_SLIDE = 'NEXT_SLIDE',
  PREV_SLIDE = 'PREV_SLIDE',
}

export default function CustomSlider({ className, headerTxt, resources, ItemsComponent }: ICustomSliderProps) {
  const styles = useStyles();
  const swiperRef = useRef(null);

  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const slidesPerView = useMemo(() => (isMobile ? 3 : 4), [isMobile]);

  const spaceBetween = useMemo(() => (isMobile ? 65 : 29), [isMobile]);

  const swiperPagination = useMemo(() => {
    return isMobile
      ? false
      : {
          clickable: true,
          horizontalClass: styles.swiperPagination,
          bulletActiveClass: styles.swiperPaginationBulletActive,
          // bulletClass: styles.swiperPaginationBullet,
        };
  }, [isMobile, styles.swiperPagination, styles.swiperPaginationBulletActive]);

  const onClickSwiperButton = useCallback(
    (action: ESwiperButton) => {
      if (action === ESwiperButton.NEXT_SLIDE) {
        swiperRef?.current?.swiper?.slideNext(500, true);
      } else {
        swiperRef?.current?.swiper?.slidePrev(500, true);
      }
      setIsBeginning(swiperRef?.current?.swiper?.isBeginning);
      setIsEnd(swiperRef?.current?.swiper?.isEnd);
    },
    [swiperRef],
  );

  return (
    <div className={cx(styles.container, className)}>
      <Typography className={styles.listTitle}>{headerTxt || t('Cities we have covered')}</Typography>
      <Swiper
        onInit={core => {
          swiperRef.current = core.el;
        }}
        modules={[Pagination, Navigation]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        direction="horizontal"
        pagination={swiperPagination}
        className={styles.swiper}>
        {resources.map(item => (
          <SwiperSlide key={item.id} style={{ cursor: 'pointer' }}>
            <ItemsComponent value={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      {!isBeginning && (
        <div className={styles.prevButton} onClick={() => onClickSwiperButton(ESwiperButton.PREV_SLIDE)}>
          <img src="/arrow_right.svg" alt="Prev city" className={styles.nextButtonIcon} />
        </div>
      )}
      {!isEnd && (
        <div className={styles.nextButton} onClick={() => onClickSwiperButton(ESwiperButton.NEXT_SLIDE)}>
          <img src="/arrow_right.svg" alt="Next city" className={styles.nextButtonIcon} />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 20,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      paddingLeft: 164,
      paddingRight: 164,
    },
  },
  listTitle: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.1,
    height: 40,
    verticalAlign: 'center',
    paddingLeft: 5.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'left',
      marginBottom: 4,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
      textAlign: 'center',
      marginBottom: 49,
    },
  },

  swiperPagination: {
    marginTop: 300,
  },
  swiperPaginationBulletActive: {
    backgroundColor: Palette.ZAAPI2,
    opacity: 1,
  },
  swiperPaginationBullet: {
    width: 10,
    height: 10,
  },
  swiper: {
    paddingBottom: 60,
    zIndex: 0,
  },
  nextButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      top: '45%',
      right: 117,
      cursor: 'pointer',
    },
  },
  nextButtonIcon: {
    width: 8,
    height: 14,
  },
  prevButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      top: '45%',
      left: 117,
      cursor: 'pointer',
      transform: 'scaleX(-1)',
    },
  },
}));
