/* eslint-disable max-lines-per-function */

import { Grid, makeStyles, Typography } from '@material-ui/core';
import ButtonGradient from 'components/Button/ButtonGradient';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAppStateFlag } from 'redux/appState/AppStateActions';
import { sGetCanLoadMoreStore } from 'redux/appState/AppStateSelectors';
import { searchStoresActionAsync } from 'redux/common/CommonActions';
import { sGetDisplayedStores, sGetSelectedCity, sGetSelectedStoreCategory } from 'redux/common/CommonSelectors';
import { Store, EAppStateFlagKeys } from 'types';
import { Province } from 'types/adminDivision';
import { PAGE_SIZE_TO_LOAD_MORE } from 'utils/constants';
import TopCategoryList from '../TopCategoryList/TopCategoryList';
import TopCityList from '../TopCityList/TopCityList';

export default function StoreList() {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedStoreCategory = useSelector(sGetSelectedStoreCategory);
  const selectedCity = useSelector(sGetSelectedCity);
  const displayedStores = useSelector(sGetDisplayedStores);
  const getLabelName = useGetOptionLabelName();
  const canLoadMoreStores = useSelector(sGetCanLoadMoreStore);
  const [pageNumber, setPageNumber] = useState(1);

  const listTitle = useMemo(() => {
    if (selectedStoreCategory?.id) {
      return getLabelName(selectedStoreCategory);
    }
    if (selectedCity?.name) {
      return t('Stores in {{city}}', { city: getLabelName(selectedCity as Province) });
    }
  }, [selectedCity, selectedStoreCategory, t, getLabelName]);

  const getStoreLogoUrl = useCallback(
    (store: Partial<Store>) => {
      return store.storeDirectoryLogoUrl || selectedStoreCategory?.logoUrl || selectedCity?.logoUrl;
    },
    [selectedStoreCategory?.logoUrl, selectedCity?.logoUrl],
  );

  const stores = useMemo(
    () => displayedStores.filter(store => getStoreLogoUrl(store)),
    [displayedStores, getStoreLogoUrl],
  );

  useEffect(() => {
    dispatch(toggleAppStateFlag(EAppStateFlagKeys.canLoadMoreStores, true));
  }, [dispatch]);

  const handleClickLoadMore = useCallback(() => {
    dispatch(
      searchStoresActionAsync({
        categoryId: selectedStoreCategory?.id,
        province: selectedCity?.name,
        pageSize: PAGE_SIZE_TO_LOAD_MORE,
        page: pageNumber + 1,
      }),
    );
    setPageNumber(pageNumber + 1);
  }, [selectedStoreCategory?.id, selectedCity?.name, pageNumber, dispatch]);

  const shouldShowLoadMoreButton = useMemo(
    () => canLoadMoreStores && displayedStores?.length >= PAGE_SIZE_TO_LOAD_MORE * pageNumber,
    [canLoadMoreStores, displayedStores?.length, pageNumber],
  );

  useEffect(() => {
    setPageNumber(1);
  }, [selectedStoreCategory?.id, selectedCity?.name]);

  return isEmpty(displayedStores) ? (
    <div>
      <div className={styles.noStoreContainer}>
        <div>{t('No Stores Found.')}</div>
        <div>{t('Please try another category or location.')}</div>
      </div>
      <TopCategoryList showSlider />
      <TopCityList headerTxt={t('Explore top cities')} className={styles.topCityList} />
    </div>
  ) : (
    <div className={styles.storeCategoryContainer}>
      <Typography className={styles.storeCategoryListTitle}>{listTitle}</Typography>
      <Grid container className={styles.storeCategoryGridContainer}>
        {stores.map(store => (
          <Grid item md={3} sm={6} xs={6} className={styles.storeCategoryItem} key={store.id}>
            <a href={`/${store.slug}`} target="_blank">
              <img src={`${getStoreLogoUrl(store)}?w=500`} className={styles.storeImage} />
              <Typography className={styles.storeCategoryName}>{store.businessName}</Typography>
            </a>
          </Grid>
        ))}
      </Grid>
      {shouldShowLoadMoreButton && (
        <div className={styles.loadMoreButton}>
          <ButtonGradient title={t('Load more...')} onClick={handleClickLoadMore} />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  storeImage: {
    borderRadius: 12,
    width: '100%',
    aspectRatio: '1',
    objectFit: 'cover',
  },
  storeCategoryContainer: {
    paddingLeft: 20 - 5.5,
    paddingRight: 20 - 5.5,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 164 - 19,
      paddingRight: 164 - 19,
    },
  },
  storeCategoryListTitle: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.1,
    height: 40,
    verticalAlign: 'center',
    paddingLeft: 5.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'left',
      marginBottom: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
      textAlign: 'center',
      marginBottom: 49,
    },
  },
  storeCategoryName: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 0.1,
    textAlign: 'left',
    height: 20,
    marginTop: 7,
    color: 'black',
    wordBreak: 'break-word',
    marginBottom: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
      lineHeight: '26px',
      fontSize: 18,
      marginBottom: 40,
    },
  },
  storeCategoryItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 5.5,
    paddingRight: 5.5,
    marginBottom: 35,
    [theme.breakpoints.up('md')]: {
      marginBottom: 38,
      paddingLeft: 19,
      paddingRight: 19,
    },
    cursor: 'pointer',
  },
  storeCategoryGridContainer: {},
  noStoreContainer: {
    fontFamily: 'Mulish',
    textAlign: 'center',
    lineHeight: '22px',
    letterSpacing: '0.3px',
    margin: '80px auto',
    fontSize: 14,

    [theme.breakpoints.up('md')]: {
      margin: '200px auto',
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  topCityList: {
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
    },
  },
  loadMoreButton: {
    width: 124,
    margin: 'auto auto 40px auto',
    '&& button': {
      fontSize: 14,
      height: 42,

      lineHeight: '18px',
      fontFamily: 'Mulish',
      letterSpacing: 0.3,
    },
    [theme.breakpoints.up('md')]: {
      width: 151,
      marginBottom: 0,
    },
  },
}));
