import DropdownComponent from 'components/Dropdown/DropdownComponent';
import useStoreCategoriesOptions from 'hooks/useStoreCategoriesOptions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IStoreCategory } from 'redux/common/Common';

export default function CategoryDropdown({
  disabled = false,
  handleSelectCategory,
  value,
}: {
  disabled?: boolean;
  handleSelectCategory: (e: IStoreCategory) => void;
  value: IStoreCategory;
}) {
  const { t } = useTranslation();
  const storeCategories = useStoreCategoriesOptions();

  const handleChange = useCallback(
    (valueParam: IStoreCategory) => {
      handleSelectCategory(valueParam);
    },
    [handleSelectCategory],
  );

  return (
    <DropdownComponent
      value={value}
      options={storeCategories}
      onChange={handleChange}
      disabled={disabled}
      placeHolder={t('Select category')}
    />
  );
}
