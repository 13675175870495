import clsx from 'clsx';
import React from 'react';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/Palette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
const useStyles = makeStyles(theme => ({
  btnSendOtp: {
    textTransform: 'none',
    fontSize: 20,
    fontWeight: 700,
  },
  btnWithThai: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  btnWithEng: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  btnSendOtpWithEmpty: ({ gradientColor }: { gradientColor?: string }) => ({
    borderRadius: 12,
    border: 'solid 1px transparent',
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), ${
      gradientColor || Palette.HEAD
    }`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow: '2px 1000px 1px #fff inset',
    color: `${Palette.ZAAPI2} !important`,
  }),
  btnSendOtpWithNonEmpty: ({ gradientColor }: { gradientColor?: string }) => ({
    borderRadius: 12,
    background: gradientColor || Palette.HEAD,
    color: Palette.WHITE,
  }),
  btnGreyBackground: {
    background: `${Palette.GREY} !important`,
    color: `${Palette.WHITE} !important`,
    borderRadius: 12,
  },
}));
export default function ButtonGradient({
  isDisabled,
  title,
  upperCaseTxt = false,
  onClick,
  style,
  isUnSaveMyAccountModalInThai,
  disableClick,
  greyButton,
  gradientColor,
  disabledClassName,
  btnNonGray,
}: {
  isDisabled?: boolean;
  title?: string;
  upperCaseTxt?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  isUnSaveMyAccountModalInThai?: boolean;
  disableClick?: boolean;
  greyButton?: boolean;
  gradientColor?: string;
  disabledClassName?: string;
  btnNonGray?: boolean;
}) {
  const styles = useStyles({ gradientColor });
  return (
    <Button
      style={style}
      fullWidth
      onClick={onClick}
      disabled={disableClick}
      classes={{ disabled: disabledClassName }}
      className={clsx(
        styles.btnSendOtp,
        isUnSaveMyAccountModalInThai ? styles.btnWithThai : styles.btnWithEng,
        isDisabled || greyButton ? styles.btnGreyBackground : styles.btnSendOtpWithNonEmpty,
        btnNonGray && styles.btnSendOtpWithEmpty,
      )}>
      {upperCaseTxt ? title?.toUpperCase() : title}
    </Button>
  );
}
