/* eslint-disable max-lines-per-function */
import clsx from 'clsx';
import ButtonGradient from 'components/Button/ButtonGradient';
import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import { ZaapiImage } from 'components/ZaapiImage/ZaapiImage';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import useIsMobile from 'hooks/useIsMobile';
import first from 'lodash/first';
import isString from 'lodash/isString';
import find from 'lodash/find';
import { useRouter } from 'next/router';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreCategory } from 'redux/common/Common';
import { setSelectedCityAction, setSelectedStoreCategory } from 'redux/common/CommonActions';
import { sGetCities, sGetSelectedCity, sGetSelectedStoreCategory } from 'redux/common/CommonSelectors';
import Palette from 'styles/Palette';
import SwipeUp from '../SwipeUp/SwipeUp';
import CategoryDropdown from './CategoryDropdown';
import styles from './dictionarySearch.module.css';
import SearchCityField from './SearchCityField';
import { RenderPropSticky } from 'react-sticky-el';
import { getTextAddressBold } from 'utils/map';
import useStoreCategoriesOptions from 'hooks/useStoreCategoriesOptions';
import { getSearchFilterDirectoryUrl } from 'utils/convert';
enum EFilterOption {
  CATEGORY = 'category',
  LOCATION = 'location',
}
const DictionarySearchBar = ({
  showButton = true,
  isFixedStyle,
  onlyShowButtonBeforeScroll,
}: {
  showButton?: boolean;
  isFixedStyle?: boolean;
  onlyShowButtonBeforeScroll?: boolean;
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const router = useRouter();
  const selectedCity = useSelector(sGetSelectedCity);
  const selectedCategory = useSelector(sGetSelectedStoreCategory);
  const storeCategories = useStoreCategoriesOptions();
  const cities = useSelector(sGetCities);
  const [openSwipeUp, setOpenSwipeUp] = useState(false);
  const [category, setCategory] = useState<IStoreCategory>(null);
  const [city, setCity] = useState<ICity>(null);
  const [province, setProvince] = useState<string>(null);
  const [filterOption, setFilterOption] = useState<EFilterOption>(null);
  const [suggestLocation, setSuggestLocation] = useState([]);
  const [shouldSearchImmediately, setShouldSearchImmediately] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setIsLoading(false);
    });
    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });
    // exec when mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, option: EFilterOption) => {
      if (isMobile) {
        e.stopPropagation();
        setOpenSwipeUp(true);
        setFilterOption(option);
      }
    },
    [isMobile],
  );

  const handleSearch = useCallback(
    (categoryId: string, provinceParam: string) => {
      const categoryData = find(storeCategories, { id: categoryId });
      const cityData = find(cities, { name: provinceParam });
      if (!provinceParam) dispatch(setSelectedCityAction(null));
      router.push(getSearchFilterDirectoryUrl(cityData?.name || provinceParam, categoryData?.slug), undefined, {
        shallow: true,
      });
      dispatch(setSelectedCityAction(cityData));
      dispatch(setSelectedStoreCategory(categoryData));
    },
    [cities, dispatch, router, storeCategories],
  );

  const handleSelectCategory = useCallback(
    (storeCategory: IStoreCategory) => {
      setCategory(storeCategory);
      if (shouldSearchImmediately) {
        handleSearch(storeCategory?.id, province);
      }
    },
    [shouldSearchImmediately, province, handleSearch],
  );

  const handleSelectCity = useCallback(
    (cityParam: ICity | string) => {
      if (isString(cityParam)) {
        const firstString = first(cityParam?.trim()?.split(','));
        setProvince(firstString);
        const cityData = find(cities, { name: firstString });
        if (cityParam) setCity(cityData);
        if (shouldSearchImmediately) handleSearch(category?.id, firstString);
      } else {
        setCity(cityParam);
        setProvince(cityParam?.name);
      }
    },
    [shouldSearchImmediately, category?.id, cities, handleSearch],
  );

  const isOpenCategory = useMemo(() => filterOption === EFilterOption.CATEGORY, [filterOption]);
  const filterOptionProps = useMemo(
    () => ({
      title: isOpenCategory ? t('Select your category') : t('Pick your location'),
      items: isOpenCategory ? storeCategories : suggestLocation,
      selectedItem: isOpenCategory ? category : city || province,
      onSelectItem: isOpenCategory ? handleSelectCategory : handleSelectCity,
      ItemsComponent: isOpenCategory ? CategoryItemsSwipeUp : LocationItemsSwipeUp,
      itemHeight: isOpenCategory ? 78 : 61,
    }),
    [
      isOpenCategory,
      storeCategories,
      category,
      city,
      suggestLocation,
      t,
      handleSelectCategory,
      handleSelectCity,
      province,
    ],
  );
  useEffect(() => {
    if (selectedCity?.id) {
      setProvince(selectedCity?.name);
    }
    if (selectedCategory?.id) {
      setCategory(selectedCategory as IStoreCategory);
    }
  }, [selectedCity, selectedCategory]);

  const shouldShowButton = useCallback(
    isFixed => {
      let result = false;
      if (onlyShowButtonBeforeScroll) result = !isFixed;
      else result = !isMobile || showButton;
      setShouldSearchImmediately(!result);
      return result;
    },
    [onlyShowButtonBeforeScroll, isMobile, showButton],
  );

  useEffect(() => {
    setShouldSearchImmediately(!showButton);
  }, [showButton]);

  return (
    <>
      {isLoading && (
        <div className={styles.containerSpinner}>
          <img src="/icons/spinner.svg" className={styles.spinner} />
        </div>
      )}
      <RenderPropSticky mode="top" onFixedToggle={null} topOffset={0} positionRecheckInterval={0}>
        {({ isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef }) => (
          <div ref={holderRef} style={holderStyles}>
            <div
              style={isFixed ? { ...wrapperStyles } : wrapperStyles}
              ref={wrapperRef}
              className={isFixedStyle ? styles.fixedAtTop : styles.moveTop}>
              <div
                className={isFixed || isFixedStyle ? styles.searchWrapperFixed : styles.searchWrapper}
                style={{ position: 'relative', marginLeft: isFixedStyle ? 0 : undefined }}>
                <div onClick={e => handleClick(e, EFilterOption.LOCATION)} className={styles.fullWidth}>
                  <SearchCityField city={province} setCity={setProvince} />
                </div>
                <div onClick={e => handleClick(e, EFilterOption.CATEGORY)} className={styles.fullWidth}>
                  <CategoryDropdown disabled={isMobile} handleSelectCategory={handleSelectCategory} value={category} />
                </div>
                {(!isMobile || shouldShowButton(isFixed)) && (
                  <ButtonGradient
                    title={t('Search')}
                    style={{ height: 42, fontSize: 14 }}
                    gradientColor={isFixed || isFixedStyle ? undefined : Palette.YELLOW_GRADIENT}
                    onClick={() => handleSearch(category?.id, province)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </RenderPropSticky>
      <SwipeUp
        title={filterOptionProps.title}
        isOpen={openSwipeUp}
        setIsOpen={setOpenSwipeUp}
        items={filterOptionProps.items}
        ItemsComponent={filterOptionProps.ItemsComponent}
        itemHeight={filterOptionProps.itemHeight}
        onSelectItem={filterOptionProps.onSelectItem}
        selectedItem={filterOptionProps.selectedItem}>
        {!isOpenCategory && (
          <SearchCityField
            city={province}
            setCity={setProvince}
            isInSwipeUp
            setSuggestLocation={setSuggestLocation}
            handleSearch={showButton ? undefined : handleSearch}
            categoryId={category?.id}
          />
        )}
      </SwipeUp>
    </>
  );
};
const CategoryItemsSwipeUp: React.FC<{ value: IStoreCategory; selected?: boolean }> = ({ value, selected }) => {
  const getOptionLabelName = useGetOptionLabelName();
  return (
    <div className={styles.categoryItem}>
      <ZaapiImage imageSrc={value?.logoUrl} actualWidth={69} containerClassName={styles.categoryImage} />
      <div className={selected ? styles.selectedCategoryText : styles.categoryText}>{getOptionLabelName(value)}</div>
    </div>
  );
};

const LocationItemsSwipeUp: React.FC<{ value: ICity | string; selected?: boolean; originalValue?: string }> = ({
  value,
  originalValue,
}) => {
  const getOptionLabelName = useGetOptionLabelName();
  const { t } = useTranslation();

  const getTextBold = useCallback(
    (valueParam: string) => getTextAddressBold(originalValue, valueParam),
    [originalValue],
  );

  return (
    <div className={clsx(styles.suggestContainer, styles.locationItem)}>
      <div className={styles.suggestionText}>
        <img src="/address_marker.svg" />

        <div>
          {isString(value) ? (
            <span dangerouslySetInnerHTML={{ __html: getTextBold(value) }} />
          ) : (
            <span>{getOptionLabelName(value)}</span>
          )}
          <span>{t('Address Detail...')}</span>
        </div>
      </div>
    </div>
  );
};
export default DictionarySearchBar;
