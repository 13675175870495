import concat from 'lodash/concat';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sGetStoreCategories } from 'redux/common/CommonSelectors';
import { ALL_CATEGORIES_FILTER_OPTION } from 'utils/constants';

const useStoreCategoriesOptions = () => {
  const storeCategories = useSelector(sGetStoreCategories);

  const categories = useMemo(() => concat(ALL_CATEGORIES_FILTER_OPTION, storeCategories), [storeCategories]);

  return categories;
};

export default useStoreCategoriesOptions;
