import { makeStyles } from '@material-ui/styles';
import useDefaultProductImageUrl from 'hooks/useDefaultProductImageUrl';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { getOptimizedImageSizeByUserDevice } from 'utils/convert';
import cx from 'classnames';

const useStyles = makeStyles({
  touchOpacity: {
    opacity: 0.5,
  },
});
interface Props {
  imageSrc: string;
  className?: string;
  alt?: string;
  actualWidth?: number;
  onClick?: () => void;
  isTouchedEffect?: boolean;
  containerClassName?: string;
}
export const ZaapiImage = ({
  imageSrc,
  className,
  alt,
  actualWidth,
  onClick,
  containerClassName,
  isTouchedEffect = true,
}: Props) => {
  const styles = useStyles();
  const defaultImage = useDefaultProductImageUrl();
  const ref = useRef(null);
  const [isBrokenImage, setIsBrokenImage] = useState(false);
  const [touched, setTouched] = useState(false);

  const toggleTouched = () => {
    setTouched(!touched);
  };

  const handleClickImage = () => {
    setTimeout(() => {
      setTouched(false);
    }, 100);
  };

  useEffect(() => {
    setIsBrokenImage(false);
  }, [imageSrc]);

  return (
    <div className={containerClassName} ref={ref}>
      <Image
        src={isBrokenImage || !imageSrc ? defaultImage : imageSrc}
        loader={({ src }) => {
          const width = getOptimizedImageSizeByUserDevice(actualWidth || ref?.current?.offsetWidth);
          return `${src}?w=${width}`;
        }}
        className={cx(className, { [styles.touchOpacity]: touched && isTouchedEffect })}
        alt={alt}
        layout="fill"
        objectFit="cover"
        objectPosition="absolute"
        onTouchStart={toggleTouched}
        onTouchEnd={handleClickImage}
        onMouseDown={toggleTouched}
        onMouseUp={handleClickImage}
        onError={() => setIsBrokenImage(true)}
        onClick={onClick}
      />
    </div>
  );
};
