import { makeStyles } from '@material-ui/core';
import LanguageDropdown from 'components/LanguageDropdown';
import HeadTitle from 'components/MainLayout/HeadTitle';
import React from 'react';
import cssStyles from './dictionaryLayout.module.css';
import DirectoryFooter from './DirectoryFooter/DirectoryFooter';
import Link from 'next/link';
interface ILayoutProps {
  children?: React.ReactNode;
}

const DictionaryLayout = (props: ILayoutProps) => {
  const styles = useStyles();

  return (
    <>
      <HeadTitle />
      <div className={cssStyles.container}>
        <div className={cssStyles.header}>
          <Link href="/" shallow>
            <img src="/images/logo.png" className={cssStyles.logoHeader} />
          </Link>
          <LanguageDropdown />
        </div>
        {props?.children}
      </div>
      <DirectoryFooter className={styles.directoryFooter} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  directoryFooter: {
    [theme.breakpoints.up('md')]: {
      marginTop: 100,
    },
  },
}));

export default DictionaryLayout;
