/* eslint-disable max-lines-per-function */
import { FormControl, MenuItem, Select } from '@material-ui/core';
import useIsMobile from 'hooks/useIsMobile';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './languageDropdown.module.css';
import get from 'lodash/get';
import { languageOptionList } from 'utils/language';
import { useSelector } from 'react-redux';
import { sGetLanguageCode } from 'redux/store/StoreSelectors';
interface DropdownSelectProps {
  name?: string;
  value: string;
}

export default function LanguageDropdown({ hideText }: { hideText?: boolean }) {
  const [language, setLanguage] = useState('en');
  const { i18n } = useTranslation();
  const isMobile = useIsMobile();
  const languageOption = useMemo(() => get(languageOptionList, language), [language]);
  const languageCode = useSelector(sGetLanguageCode);

  useEffect(() => {
    if (get(languageOptionList, i18n.language) && i18n.language !== language) {
      setLanguage(i18n.language);
    }
  }, [i18n.language, language]);

  useEffect(() => {
    if (!get(languageOptionList, i18n.language) && languageCode) {
      i18n.changeLanguage(languageCode);
    }
  }, [languageCode, i18n]);

  const handleChange = useCallback(
    (event: ChangeEvent<DropdownSelectProps>) => {
      setLanguage(event.target.value);
      i18n.changeLanguage(event.target.value);
    },
    [i18n],
  );

  const renderValue = useCallback(() => {
    return (
      <div className={styles.itemStyle} data-testId="dLanguageSelected">
        <img src={languageOption?.image} className={styles.iconStyle} />
        {!hideText && <span>{isMobile ? languageOption?.shortTitle : languageOption?.title}</span>}
      </div>
    );
  }, [isMobile, languageOption, hideText]);

  return (
    <FormControl>
      <Select
        value={language}
        onChange={handleChange}
        disableUnderline
        renderValue={renderValue}
        classes={{
          root: styles.selectValueStyle,
          icon: hideText && styles.hiddenTextContainer,
          select: hideText && styles.hiddenSelectContainer,
        }}
        MenuProps={{ classes: { paper: styles.dropdownPaper, list: styles.menuListStyle } }}>
        <MenuItem value={languageOptionList.en.value} classes={{ selected: styles.selectedItem }}>
          <div className={styles.itemStyle}>
            <img src={languageOptionList.en.image} className={styles.iconStyle} />
            {languageOptionList.en.title}
          </div>
        </MenuItem>
        <MenuItem value={languageOptionList.th.value} classes={{ selected: styles.selectedItem }}>
          <div className={styles.itemStyle}>
            <img src={languageOptionList.th.image} className={styles.iconStyle} />
            {languageOptionList.th.title}
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
