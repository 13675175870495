/* eslint-disable max-lines-per-function */

import { Grid, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import ButtonGradient from 'components/Button/ButtonGradient';
import { ZaapiImage } from 'components/ZaapiImage/ZaapiImage';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import useIsMobile from 'hooks/useIsMobile';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sGetCities, sGetSelectedStoreCategory } from 'redux/common/CommonSelectors';
import { PAGE_SIZE_TO_LOAD_MORE } from 'utils/constants';
import { getSearchFilterDirectoryUrl } from 'utils/convert';
import CustomSlider from '../CustomSlider';
import { ICity } from './TopCityList.d';

interface ITopCityListProps {
  className?: string;
  headerTxt?: string;
}

export default function TopCityList({ className, headerTxt }: ITopCityListProps) {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const cities = useSelector(sGetCities);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const citiesWithStore = useMemo(() => {
    return cities.filter(city => !!city.storeCount).filter(store => !!store.logoUrl);
  }, [cities]);

  useEffect(() => {
    if (citiesWithStore.length <= PAGE_SIZE_TO_LOAD_MORE) {
      setShowLoadMore(false);
    }
  }, [citiesWithStore]);

  const onClickLoadMore = useCallback(() => {
    if ((pageNumber + 1) * PAGE_SIZE_TO_LOAD_MORE >= citiesWithStore?.length) {
      setShowLoadMore(false);
    } else {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, citiesWithStore?.length]);

  return isMobile ? (
    <CustomSlider
      resources={citiesWithStore}
      ItemsComponent={ItemCitySlider}
      headerTxt={headerTxt || t('Cities we have covered')}
    />
  ) : (
    <div className={cx(styles.container, className)}>
      <Typography variant="h2" className={styles.listTitle}>
        {headerTxt || t('Cities we have covered')}
      </Typography>
      <Grid container>
        {citiesWithStore.slice(0, pageNumber * PAGE_SIZE_TO_LOAD_MORE).map(store => (
          <Grid item md={3} sm={6} xs={6} key={store.id} className={styles.cityItem}>
            <ItemCitySlider value={store} />
          </Grid>
        ))}
      </Grid>
      {showLoadMore && (
        <div className={styles.loadMoreButton}>
          <ButtonGradient title={t('Load more...')} onClick={onClickLoadMore} />
        </div>
      )}
    </div>
  );
}
const ItemCitySlider: React.FC<{ value: ICity }> = ({ value }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const getLabelName = useGetOptionLabelName();
  const selectedStoreCategory = useSelector(sGetSelectedStoreCategory);

  const getStoreCountText = useCallback(
    (city: ICity) => {
      if (city.storeCount === 1) return t('{{x}} Shop', { x: city.storeCount });
      return t('{{x}} Shops', { x: city.storeCount });
    },
    [t],
  );

  return (
    <Link href={getSearchFilterDirectoryUrl(value?.name, selectedStoreCategory?.id)} shallow passHref>
      <a>
        <ZaapiImage imageSrc={value.logoUrl} className={styles.cityImage} containerClassName={styles.cityImage} />
        <Typography variant="h3" className={styles.cityName}>
          {getLabelName(value)}
        </Typography>
        <Typography className={styles.storeCount}>{getStoreCountText(value)}</Typography>
      </a>
    </Link>
  );
};
const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 164,
      paddingRight: 164,
    },
  },
  listTitle: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.1,
    height: 40,
    verticalAlign: 'center',
    paddingLeft: 5.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'left',
      marginBottom: 4,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
      textAlign: 'center',
      marginBottom: 49,
    },
  },
  cityImage: {
    aspectRatio: '1',
    width: 130,
    height: 130,
    borderRadius: 12,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 'auto',
    },
    objectFit: 'cover',
  },
  cityName: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 0.1,
    textAlign: 'left',
    height: 20,
    marginTop: 7,
    color: 'black',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 130,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      height: 24,
      marginTop: 20,
      width: '100%',
    },
  },
  storeCount: {
    fontFamily: 'Mulish',
    fontSize: 14,
    lineHeight: '20px',
    marginTop: 4,
    letterSpacing: 0.2,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      lineHeight: '20px',
      marginTop: 12,
    },
  },
  cityItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 5.5,
    paddingRight: 5.5,
    marginBottom: 35,
    [theme.breakpoints.up('md')]: {
      marginBottom: 38,
      paddingLeft: 19,
      paddingRight: 19,
    },
    cursor: 'pointer',
  },
  loadMoreButton: {
    width: 151,
    margin: 'auto',
    '&& button': {
      fontSize: 14,
      height: 42,

      lineHeight: '18px',
      fontFamily: 'Mulish',
      letterSpacing: 0.3,
    },
  },
}));
