import Head from 'next/head';

const HeadTitle = () => {
  return (
    <Head>
      <title>ค้นหาสินค้าที่ดีที่สุดสำหรับคุณบน Shop.Zaapi.com</title>
      <link rel="icon" href="/images/favicon.png" />
    </Head>
  );
};

export default HeadTitle;
