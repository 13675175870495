/* eslint-disable max-lines-per-function */
import { makeStyles } from '@material-ui/core';
import DictionaryLayout from 'components/BuyerDictionaryLayout';
import DictionarySearchBar from 'components/BuyerDictionaryLayout/DictionarySearchBar';
import LandingBackground from 'components/BuyerDictionaryLayout/LandingBackground';
import StoreList from 'components/BuyerDirectory/StoreList/StoreList';
import useStoreCategoriesOptions from 'hooks/useStoreCategoriesOptions';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { searchStoresActionAsync, setSelectedCityAction, setSelectedStoreCategory } from 'redux/common/CommonActions';
import { sGetCities } from 'redux/common/CommonSelectors';
import { ALL_CATEGORIES_FILTER_OPTION, PAGE_SIZE_TO_LOAD_MORE } from 'utils/constants';
import TopCategoryList from '../../components/BuyerDirectory/TopCategoryList/TopCategoryList';
import TopCityList from '../../components/BuyerDirectory/TopCityList/TopCityList';

export default function BuyerDictionary() {
  const styles = useStyles();
  const { t } = useTranslation();
  const storeCategories = useStoreCategoriesOptions();
  const cities = useSelector(sGetCities);

  const router = useRouter();
  const dispatch = useDispatch();
  const { filterParams } = router.query;

  useEffect(() => {
    if (storeCategories?.length && cities?.length && filterParams?.length > 0) {
      const storeCategory = storeCategories.find(c => c.slug === filterParams[0]);
      const cityName = storeCategory ? (filterParams?.length > 1 ? filterParams[1] : undefined) : filterParams[0];
      const selectedCity = cities.find(c => c.name === cityName);

      if (storeCategory?.id || selectedCity?.name || (isString(cityName) && !isEmpty(cityName))) {
        dispatch(
          searchStoresActionAsync({
            categoryId: storeCategory?.id === ALL_CATEGORIES_FILTER_OPTION.id ? '' : storeCategory?.id,
            province: selectedCity?.name || (cityName as string),
            page: 1,
            pageSize: PAGE_SIZE_TO_LOAD_MORE,
          }),
        );
      }
      if (!selectedCity && !isEmpty(cityName)) {
        dispatch(
          setSelectedCityAction({ name: cityName as string, logoUrl: '', id: '', localName: cityName as string }),
        );
      } else {
        dispatch(setSelectedCityAction(selectedCity));
      }
      dispatch(setSelectedStoreCategory(storeCategory));
    }
  }, [storeCategories, filterParams, cities, dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [filterParams]);

  return (
    <DictionaryLayout>
      <LandingBackground isSpecificFilter={!isEmpty(filterParams)}>
        <DictionarySearchBar onlyShowButtonBeforeScroll={isMobile} />
      </LandingBackground>

      {router.isReady && (
        <div>
          {filterParams ? (
            <StoreList />
          ) : (
            <>
              <TopCategoryList />
              <TopCityList className={styles.topCityList} headerTxt={t('Explore top cities')} />
            </>
          )}
        </div>
      )}
    </DictionaryLayout>
  );
}

const useStyles = makeStyles(theme => ({
  topCityList: {
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
    },
  },
}));
