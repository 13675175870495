/* eslint-disable max-lines-per-function */

import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

const socialButtons = [
  {
    iconPath: '/icons/facebook.svg',
    linkTo: 'https://www.facebook.com/Zaapi_th-107537101545250',
  },
  {
    iconPath: '/icons/instagram.svg',
    linkTo: 'https://www.instagram.com/zaapithailand/',
  },
  {
    iconPath: '/icons/line.svg',
    linkTo: 'https://lin.ee/4HZ0U84',
  },
  {
    iconPath: '/icons/linkedin.svg',
    linkTo: 'https://www.linkedin.com/company/zaapi-official/#',
  },
];

interface ISocialButtons {
  className?: string;
}

export default function SocialButtons({ className }: ISocialButtons) {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.socialLinkContainer}>
        {socialButtons.map(socialButton => (
          <a href={socialButton.linkTo} target="_blank" key={socialButton.linkTo}>
            <img src={socialButton.iconPath} className={styles.socialButton} />
          </a>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  socialLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 39,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  socialButton: {
    marginRight: 7,
    marginLeft: 7,
    width: 38,
    height: 38,
    [theme.breakpoints.up('md')]: {
      width: 42,
      height: 42,
    },
  },
}));
