/* eslint-disable max-lines-per-function */
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ZaapiImage } from 'components/ZaapiImage/ZaapiImage';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import { find, map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { ChangeEvent, useCallback } from 'react';
import { IStoreCategory } from 'redux/common/Common';
import Palette from 'styles/Palette';
const useStyles = makeStyles(theme => ({
  fullWidth: { width: '100%' },
  dropdownPaper: {
    marginTop: 54,
    borderRadius: 12,
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2) !important',
    [theme.breakpoints.down('xs')]: {
      top: 54,
    },
  },
  menuListStyle: {
    padding: `0 0 21px`,
  },
  itemStyle: {
    borderRadius: `12px !important`,
    background: Palette.WHITE,
    padding: '11px 16px',
    fontSize: 14,
    lineHeight: '20px',
    color: Palette.GREY,
    letterSpacing: '0.2px',
    fontFamily: 'mulish',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'left',
  },
  menuItemStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    color: Palette.BLACK,
    '&& span': {
      width: '70%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative !important',
      fontWeight: 600,
    },
    '&& img': {
      width: '60px !important',
      height: '45px !important',
      objectFit: 'cover',
      borderRadius: 12,
      position: 'relative !important',
    },
    '&& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  selectedItem: {
    color: Palette.ZAAPI2,
    background: Palette.WHITE,
  },
  selectValueStyle: {
    background: Palette.WHITE,
    borderRadius: `12px !important`,
    padding: 0,
  },
}));

const ITEM_HEIGHT = 60;
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6,
      width: 250,
    },
  },
};

export default function DropdownComponent({
  value,
  onChange,
  options,
  disabled = false,
  placeHolder,
}: {
  value: IStoreCategory;
  onChange: (e: IStoreCategory) => void;
  options: IStoreCategory[];
  disabled?: boolean;
  placeHolder?: string;
}) {
  const styles = useStyles();
  const handleChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const id = find(options, { id: event.target.value });
      onChange(id);
    },
    [onChange, options],
  );

  const getOptionLabelName = useGetOptionLabelName();

  const renderValue = useCallback(() => {
    return (
      <div className={styles.itemStyle} style={!isEmpty(value) ? { color: Palette.BLACK } : {}}>
        {!isEmpty(value) ? getOptionLabelName(value) : placeHolder}
      </div>
    );
  }, [value, placeHolder, getOptionLabelName, styles.itemStyle]);

  return (
    <FormControl classes={{ root: styles.fullWidth }}>
      <Select
        disabled={disabled}
        value={value?.id || ''}
        onChange={handleChange}
        disableUnderline
        renderValue={renderValue}
        displayEmpty
        classes={{ select: styles.selectValueStyle }}
        defaultValue={value?.id}
        MenuProps={{
          ...MenuProps,
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          classes: { paper: styles.dropdownPaper, list: styles.menuListStyle },
        }}>
        {map(options, e => (
          <MenuItem value={e.id} classes={{ selected: styles.selectedItem, root: styles.menuItemStyle }}>
            <span>{getOptionLabelName(e)}</span>
            {e?.logoUrl && <ZaapiImage imageSrc={e?.logoUrl} actualWidth={60} />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
