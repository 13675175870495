/* eslint-disable max-lines-per-function */
import { makeStyles, Theme } from '@material-ui/core';
import { ZaapiImage } from 'components/ZaapiImage/ZaapiImage';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { sGetSelectedCity, sGetSelectedStoreCategory } from 'redux/common/CommonSelectors';
import Palette from 'styles/Palette';

export const useStyles = makeStyles((theme: Theme) => ({
  descriptionWrapper: {
    width: '100%',
    position: 'relative',
    marginBottom: 80,
    height: 406,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      height: 245,
    },
  },
  imageDescription: {
    width: '100%',
    maxHeight: 406,
    objectFit: 'cover',
    filter: 'brightness(40%)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 245,
    },
  },
  descriptionText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('xs')]: {
      top: 'unset',
      bottom: 30,
      left: 0,
      transform: 'none',
      margin: 'auto 18.5px',
    },
  },
  title: {
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: 44,
    lineHeight: '56px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: Palette.WHITE,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      lineHeight: '56px',
      textAlign: 'left',
    },
  },
  subTitle: {
    fontFamily: 'Mulish',
    fontSize: 16,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: Palette.F5F5F5,
    marginTop: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      textAlign: 'left',
      marginTop: 0,
    },
  },
}));

export default function CategoryOrCityDescription() {
  const styles = useStyles();
  const getLabelName = useGetOptionLabelName();
  const selectedCity = useSelector(sGetSelectedCity);
  const selectedStoreCategory = useSelector(sGetSelectedStoreCategory);
  const ref = useRef(null);
  const imageLanding = useMemo(() => {
    if (selectedStoreCategory) {
      return selectedStoreCategory?.logoUrl;
    } else {
      return selectedCity?.logoUrl;
    }
  }, [selectedStoreCategory, selectedCity]);

  const title = useMemo(() => {
    if (selectedStoreCategory) {
      return getLabelName(selectedStoreCategory);
    } else {
      return getLabelName(selectedCity);
    }
  }, [selectedStoreCategory, selectedCity, getLabelName]);

  return (
    <div className={styles.descriptionWrapper} ref={ref}>
      <ZaapiImage imageSrc={imageLanding} className={styles.imageDescription} actualWidth={ref?.current?.offsetWidth} />
      <div className={styles.descriptionText}>
        <h1 className={styles.title}>{title}</h1>
      </div>
    </div>
  );
}
