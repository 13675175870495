/* eslint-disable max-lines-per-function */

import { makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';
import Palette from 'styles/Palette';
import DownloadAppButtons from './DownloadAppButtons';
import SocialButtons from './SocialButtons';

interface IDirectoryFooter {
  className?: string;
}

export default function DirectoryFooter({ className }: IDirectoryFooter) {
  const styles = useStyles();

  const { t } = useTranslation();

  const marketingSite = process.env.NEXT_PUBLIC_ZAAPI_MARKETING_SITE;
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.storeLinkContainer}>
        <Typography className={styles.footerTitle}>{t('Get started free with Zaapi!')}</Typography>
        <Typography className={styles.footerSubtitle}>
          {t('Download Zaapi and create your own free online store in seconds.')}
        </Typography>
        <DownloadAppButtons />
        <div className={styles.divider} />

        <div className={styles.bottomContainer}>
          <Typography className={styles.copyrightText}>
            <Trans i18nKey="copyrightDirectory">
              Copyright © 2022{' '}
              <a href={marketingSite} target="_blank" style={{ textDecoration: 'underline' }}>
                ZAAPI
              </a>
              . All Rights Reserved.
            </Trans>
          </Typography>
          <SocialButtons className={styles.socialButtonContainer} />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {},
  storeLinkContainer: {
    height: 412,
    backgroundColor: Palette.ZAAPI2,
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: 519,
    },
  },
  copyrightText: {
    fontFamily: 'Mulish',
    lineHeight: '16px',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.2,
    color: '#C3CBCD',
    marginTop: 42,
    height: 64,
    backgroundColor: '#373F41',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.up('md')]: {
      color: 'white',
      fontSize: 14,
      fontWeight: 400,
      alignSelf: 'start',
      backgroundColor: 'unset',
      width: 'auto',
      marginTop: 'auto',
    },
  },
  footerTitle: {
    fontFamily: 'Mulish',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.1,
    marginRight: 20,
    marginLeft: 20,
    textAlign: 'center',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      fontSize: 38,
    },
  },
  footerSubtitle: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: 0.3,
    marginRight: 20,
    marginLeft: 20,
    textAlign: 'center',
    color: 'white',
    marginTop: 2,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      marginTop: 20,
    },
  },
  downloadAppButton: {
    width: 155,
    height: 47,
  },
  appStoreButton: {
    marginTop: 20,
  },
  googlePlayButton: {
    marginTop: 13,
  },
  divider: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 1,
      backgroundColor: '#F7F7F7',
      width: 1111,
      marginTop: 50,
      opacity: 0.15,
    },
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 46,
      width: 1111,
      flexDirection: 'row',
    },
  },
  socialButtonContainer: {
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      marginLeft: 'auto',
    },
  },
}));
