import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { District, Province } from 'types/adminDivision';
import useIsThai from './useIsThai';

interface IAdminDivision {
  name?: string;
  localName?: string;
  type?: string;
  country?: string;
  id?: string;
}

export default function useGetOptionLabelName() {
  const isThai = useIsThai();
  const { i18n } = useTranslation();
  const shouldUseLocalName = useMemo(() => isThai || i18n.language === 'th', [isThai, i18n.language]);

  const getOptionLabelName = useCallback(
    (option: IAdminDivision | Province | District) => {
      if (option?.name) {
        return shouldUseLocalName && option?.localName ? option?.localName : option.name;
      }
      return '';
    },
    [shouldUseLocalName],
  );
  return getOptionLabelName;
}
