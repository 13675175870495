/* eslint-disable max-lines-per-function */

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ZaapiImage } from 'components/ZaapiImage/ZaapiImage';
import useGetOptionLabelName from 'hooks/useGetOptionLabelName';
import useIsMobile from 'hooks/useIsMobile';
import Link from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IStoreCategory } from 'redux/common/Common';
import { sGetSelectedCity, sGetStoreCategories } from 'redux/common/CommonSelectors';
import { getSearchFilterDirectoryUrl } from 'utils/convert';
import CustomSlider from '../CustomSlider';

export default function TopCategoryList({ showSlider = false }: { showSlider?: boolean }) {
  const styles = useStyles();

  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const getLabelName = useGetOptionLabelName();
  const storeCategories = useSelector(sGetStoreCategories);
  const selectedCity = useSelector(sGetSelectedCity);

  const storeCategoryListTitle = useMemo(() => {
    return isMobile ? t('Explore best selling categories') : t('Explore top categories');
  }, [isMobile, t]);

  return showSlider ? (
    <CustomSlider
      resources={storeCategories}
      ItemsComponent={ItemCategorySlider}
      headerTxt={t('Explore other categories')}
    />
  ) : (
    <div className={styles.storeCategoryContainer}>
      <Typography variant="h2" className={styles.storeCategoryListTitle}>
        {storeCategoryListTitle}
      </Typography>
      <Grid container className={styles.storeCategoryGridContainer}>
        {storeCategories.map(storeCategory => (
          <Grid item md={4} sm={6} xs={6} className={styles.storeCategoryItem} key={storeCategory.id}>
            <Link href={getSearchFilterDirectoryUrl(selectedCity?.name, storeCategory.slug)} shallow passHref>
              <a>
                <div className={styles.imageContainer}>
                  <ZaapiImage
                    imageSrc={storeCategory.logoUrl}
                    className={styles.storeCategoryImage}
                    containerClassName={styles.storeCategoryImage}
                  />

                  {!isMobile && <div className={styles.imageOverlay} />}
                </div>
                <Typography variant="h3" className={styles.storeCategoryName}>
                  {getLabelName(storeCategory)}
                </Typography>
              </a>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const ItemCategorySlider: React.FC<{ value: IStoreCategory }> = ({ value }) => {
  const styles = useStyles();
  const getLabelName = useGetOptionLabelName();
  const selectedCity = useSelector(sGetSelectedCity);

  return (
    <Link href={getSearchFilterDirectoryUrl(selectedCity?.name, value.slug)} shallow passHref>
      <a>
        <ZaapiImage
          imageSrc={value.logoUrl}
          className={styles.categorySliderImage}
          containerClassName={styles.categorySliderImage}
        />
        <Typography className={styles.categorySliderName}>{getLabelName(value)}</Typography>
      </a>
    </Link>
  );
};
const useStyles = makeStyles(theme => ({
  storeCategoryImage: {
    borderRadius: 12,
    width: '100%',
    aspectRatio: (345 / 261).toString(),
    objectFit: 'cover',
    position: 'relative',
  },
  storeCategoryContainer: {
    paddingLeft: 20 - 5.5,
    paddingRight: 20 - 5.5,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 164 - 19,
      paddingRight: 164 - 19,
    },
  },
  storeCategoryListTitle: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.1,
    height: 40,
    verticalAlign: 'center',
    paddingLeft: 5.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'left',
      marginBottom: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
      textAlign: 'center',
      marginBottom: 49,
    },
  },
  storeCategoryName: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 0.1,
    textAlign: 'left',
    height: 20,
    marginTop: 7,
    color: 'black',
    [theme.breakpoints.up('md')]: {
      marginTop: -46,
      height: 24,
      color: 'white',
      paddingLeft: 22,
      fontSize: 18,
      fontWeight: 700,
      position: 'relative',
    },
  },
  storeCategoryItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 5.5,
    paddingRight: 5.5,
    marginBottom: 35,
    [theme.breakpoints.up('md')]: {
      marginBottom: 38,
      paddingLeft: 19,
      paddingRight: 19,
    },
    cursor: 'pointer',
  },
  storeCategoryGridContainer: {},
  imageOverlay: {
    display: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    background:
      'linear-gradient(180.25deg, rgba(0, 0, 0, 0) 41.61%, rgba(0, 0, 0, 0.38) 81%, rgba(0, 0, 0, 0.73) 99.78%)',
    borderRadius: 12,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  imageContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  categorySliderImage: {
    aspectRatio: '1',
    width: 130,
    height: 130,
    borderRadius: 12,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 'auto',
    },
    objectFit: 'cover',
  },
  categorySliderName: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 0.1,
    textAlign: 'left',
    height: 20,
    marginTop: 7,
    color: 'black',
    [theme.breakpoints.up('md')]: {
      height: 24,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      marginTop: 20,
    },
  },
}));
