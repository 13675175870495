export const languageOptionList = {
  en: {
    image: '/english_lang.svg',
    title: 'English',
    value: 'en',
    shortTitle: 'ENG',
  },
  th: {
    image: '/thailand_lang.svg',
    title: 'ภาษาไทย',
    value: 'th',
    shortTitle: 'ไทย',
  },
};
