/* eslint-disable max-lines-per-function */
import { a, config, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import useIsMobile from 'hooks/useIsMobile';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IStoreCategory } from 'redux/common/Common';
import styles from './swipeUp.module.css';
import get from 'lodash/get';
import map from 'lodash/map';
import { Backdrop } from '@material-ui/core';
type ItemType = IStoreCategory | ICity | string;
interface ILayoutProps {
  children?: React.ReactNode;
  items?: IStoreCategory[] | ICity[] | string[];
  title: string;
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  itemHeight?: number;
  ItemsComponent?: React.FC<{ value: ItemType; selected?: boolean; originalValue?: string }>;
  onSelectItem?: (e: ItemType) => void;
  selectedItem: ItemType;
}

export default function SwipeUp({
  children,
  title,
  isOpen,
  setIsOpen,
  items,
  ItemsComponent,
  itemHeight,
  onSelectItem,
  selectedItem,
}: ILayoutProps) {
  const isMobile = useIsMobile();
  const height = useMemo(() => Math.min(5, items?.length || 2) * itemHeight + 130, [items?.length, itemHeight]);
  const itemsHeight = useMemo(() => items.length * itemHeight + 30, [items.length, itemHeight]);
  const [{ y }, api] = useSpring(() => ({ y: height }));
  const itemComponent = useSpring(() => ({ y: 0 }));
  const itemApi = itemComponent[1];
  const itemY = itemComponent[0].y;

  const open = useCallback(() => {
    api.start({ y: 0, immediate: false, config: config.stiff });
    itemApi.start({ y: 0, immediate: false, config: config.stiff });
  }, [api, itemApi]);

  const close = (velocity = 0) => {
    setIsOpen(false);
    api.start({ y: height, immediate: false, config: { ...config.stiff, velocity } });
  };

  const bind = useDrag(({ down, offset: [oy] }) => itemApi.start({ y: oy, immediate: down }), {
    bounds: { left: 0, right: 0, top: -itemsHeight, bottom: 0 },
    rubberband: true,
  });

  const display = y.to(py => (py < height ? 'block' : 'none'));

  useEffect(() => {
    if (isOpen) open();
    // only exec when isOpen is triggered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getValueTitle = useCallback((text, key?: string) => {
    return isString(text) ? text : get(text, [key]);
  }, []);

  return (
    <div style={isMobile ? { overflow: 'hidden' } : { display: 'none' }}>
      {isOpen && (
        <style jsx global>{`
          body {
            overflow: hidden;
          }
        `}</style>
      )}
      <Backdrop open={isOpen} className={styles.backdrop} />

      <a.div className={styles.sheet} style={{ display, bottom: `calc(-100vh + ${height - 100}px)`, y }}>
        <div className={styles.titleSwipe}>
          <span>{title}</span>
          <img src="/remove_x.svg" onClick={() => close(0)} />
        </div>
        {children}
        <a.div {...bind()} className={styles.itemContainer} style={{ y: itemY, height: height - 100 }}>
          {map(items, (entry: ICity | IStoreCategory | string) => (
            <div
              key={getValueTitle(entry, 'name')}
              onClick={() => {
                if (onSelectItem) onSelectItem(entry);
                close(0);
              }}>
              <ItemsComponent
                value={entry}
                selected={isEqual(getValueTitle(entry, 'id'), getValueTitle(selectedItem, 'id'))}
                originalValue={selectedItem as string}
              />
            </div>
          ))}
        </a.div>
      </a.div>
    </div>
  );
}
