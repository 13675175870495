import useIsMobile from 'hooks/useIsMobile';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryOrCityDescription from './CategoryOrCityDescription';
import styles from './dictionaryLayout.module.css';
import DictionarySearchBar from './DictionarySearchBar';
interface ILayoutProps {
  children?: React.ReactElement;
  isSpecificFilter?: boolean;
}

const LandingBackground = (props: ILayoutProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const srcLandingBackgroundImg = useMemo(
    () => (isMobile ? '/backgroundLandingMob.svg' : '/backgroundLanding.svg'),
    [isMobile],
  );
  return props?.isSpecificFilter ? (
    <div className={styles.landingSubtext}>
      <DictionarySearchBar isFixedStyle showButton={!isMobile} />
      <CategoryOrCityDescription />
    </div>
  ) : (
    <div className={styles.landingBackgroundWrapper}>
      <img src={srcLandingBackgroundImg} className={styles.landingBackgroundImg} />
      <div className={styles.textLandingContainer}>
        {isMobile && <img src="/directory-main-icon.svg" alt="directory icon" className={styles.directoryIconMob} />}
        <h1 className={styles.landingHeaderText}>{t('Discover stores on Zaapi')}</h1>
        <div className={styles.landingSubtext}>
          {t('Use our directory to find anything you want from the thousands of stores on Zaapi.')}
        </div>
        <div className={styles.childrenLandingWrapper}>{props.children}</div>
      </div>
    </div>
  );
};

export default LandingBackground;
