/* eslint-disable max-lines-per-function */
import { CircularProgress } from '@material-ui/core';
import useIsMobile from 'hooks/useIsMobile';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import map from 'lodash/map';
import takeRight from 'lodash/takeRight';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  fetchLocationAutocompleteResults,
  getAddressComponentTextByType,
  getCurrentLocation,
  getTextAddressBold,
  getValueInArray,
} from 'utils/map';
import styles from './dictionarySearch.module.css';
interface Props {
  isInSwipeUp?: boolean;
  city?: string;
  setCity: (e: string) => void;
  setSuggestLocation?: (e: string[]) => void;
  handleSearch?: (categoryId: string, city: string) => void;
  categoryId?: string;
}
export default function SearchCityField({
  city,
  setCity,
  isInSwipeUp = false,
  setSuggestLocation,
  handleSearch,
  categoryId,
}: Props) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const [browserAllowLocation, setBrowserAllowLocation] = useState(false);
  const router = useRouter();
  const [isLoadingCurrentPlace, setIsLoadingCurrentPlace] = useState(false);

  const handleSelectPlace = useCallback(
    (address: string, _placeId: string, suggestion) => {
      let addressTemp = '';
      if (!suggestion) {
        setCity(address);
        addressTemp = address;
      } else {
        const province = head(takeRight(suggestion?.terms, 2));
        const value = get(province, ['value']);
        setCity(value);
        addressTemp = value;
      }
      if (handleSearch) {
        handleSearch(categoryId, addressTemp);
      }
    },
    [handleSearch, categoryId, setCity],
  );

  const getCurrentResult = useCallback(
    e => {
      const administrative_area_level_1 = getAddressComponentTextByType(
        e?.addressComponents,
        'administrative_area_level_1',
      );
      const province = getValueInArray(e.description, 1) || administrative_area_level_1 || '';
      setCity(province);
      setIsLoadingCurrentPlace(false);
    },
    [setCity],
  );

  const handleBrowserAllowLocation = useCallback((isAllowed: boolean) => {
    setIsLoadingCurrentPlace(false);
    setBrowserAllowLocation(isAllowed);
  }, []);

  const getCurrentPlace = useCallback(() => {
    setIsLoadingCurrentPlace(true);
    getCurrentLocation(getCurrentResult, handleBrowserAllowLocation);
  }, [getCurrentResult, handleBrowserAllowLocation]);

  useEffect(() => {
    if (router?.query?.city) {
      setCity((router?.query?.city as string) || '');
    } else {
      getCurrentPlace();
    }
  }, [router?.query?.city, getCurrentPlace, setCity]);

  const getDescriptionText = useCallback(suggestion => {
    const address = takeRight(suggestion?.terms, 2);
    return join(map(address, 'value'), ', ');
  }, []);

  const getTextBold = useCallback((text: string) => getTextAddressBold(city, text), [city]);

  const getIcon = useMemo(
    () => (browserAllowLocation ? '/current-location.svg' : '/search.svg'),
    [browserAllowLocation],
  );

  const fetchPredictions = useCallback(() => {
    if (isEmpty(ref?.current?.state?.suggestions)) {
      ref?.current?.fetchPredictions();
    }
  }, []);

  const fetchLocationsSuggestions = useMemo(
    () =>
      debounce(async (input: string) => {
        const results = await fetchLocationAutocompleteResults({ types: ['(cities)'], input });
        setSuggestLocation(map(results, o => getDescriptionText(o)));
      }, 300),
    [getDescriptionText, setSuggestLocation],
  );

  useEffect(() => {
    if (setSuggestLocation && ref?.current) {
      fetchLocationsSuggestions(city);
    }
  }, [setSuggestLocation, city, fetchLocationsSuggestions]);
  const isLocationInputDisabled = useMemo(() => {
    return isMobile ? !isInSwipeUp : false;
  }, [isMobile, isInSwipeUp]);
  return (
    <PlacesAutocomplete
      value={city}
      onChange={setCity}
      onSelect={handleSelectPlace}
      searchOptions={{ types: ['(cities)'] }}
      ref={ref}>
      {({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div>
          <div className={styles.inputSearchContainer}>
            {isLoadingCurrentPlace ? (
              <CircularProgress size={21} />
            ) : (
              <input
                {...getInputProps()}
                className={styles.inputBox}
                placeholder={t('Enter your location')}
                disabled={isLocationInputDisabled}
              />
            )}

            <img
              src={getIcon}
              alt="Get Current Location"
              onClick={browserAllowLocation ? getCurrentPlace : fetchPredictions}
            />
          </div>

          <div className={styles.suggestContainer} style={isInSwipeUp ? { display: 'none' } : {}}>
            {suggestions.map(suggestion => (
              <div
                {...getSuggestionItemProps(suggestion)}
                key={suggestion.description}
                className={styles.suggestionText}>
                <img src="/address_marker.svg" />

                <div>
                  <span dangerouslySetInnerHTML={{ __html: getTextBold(getDescriptionText(suggestion)) }} />
                  <span>{t('Address Detail...')}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
